import LoadingSpinner from '@/components/LoadingSpinner'
import { useAppRouter } from '@/src/useAppRouter'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import LoginScreen from '../components/LoginScreen'

export default function LoginPage() {
  const router = useAppRouter()
  const { key, email } = router.query

  const [loaded, setLoaded] = useState(false)
  const [previousEmail, setPreviousEmail] = useState('')
  useEffect(() => {
    setPreviousEmail(localStorage.getItem('email'))
    setLoaded(true)
  }, [])

  if (!loaded) return <LoadingSpinner size="medium"/>

  return <>
    <Head>
      <title>Social Jazz Login</title>
    </Head>
    <div className='background'>
      <LoginScreen loginKey={key} defaultEmail={email || previousEmail}/>
    </div>

    <style jsx>{`
      .background {
        width: 100vw;
        min-height: 100vh;
        background-image: url('/img/signup-background.png');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    `}</style>
  </>
}
